// Current class display for global navigation
export default function xxx() {
  const navLink = document.getElementsByClassName('js-link');
  for (let i = 0; i < navLink.length; i++) {
    if (navLink[ i ].href === location.href) {
      // navLink[ i ].classList.add('is-current');
      navLink[ i ].setAttribute('aria-current', 'page');
    }
  }
}
