//adjusting panel height
export default function autoHeight() {
  const elem = document.querySelectorAll('.js-panel');
  let elemMaxHeight = 0;
  const tempDisplayStyle = {
    position: 'absolute',
    visibility: 'hidden',
    display: 'block',
    width: '100%',
  };

  Array.prototype.forEach.call(elem, function (elemChild) {
    const originalStyle = elemChild.getAttribute('style');
    elemChild.style.height = '';
    Object.assign(elemChild.style, tempDisplayStyle);

    elemMaxHeight = Math.max(elemMaxHeight, elemChild.offsetHeight);

    elemChild.setAttribute('style', originalStyle);
  });

  Array.prototype.forEach.call(elem, function (elemChild) {
    elemChild.style.height = elemMaxHeight + 'px';
  });
}
