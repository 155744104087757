import hamburgerMenu from './modules/hamburgerMenu';
import smoothScroll from './modules/smoothScroll';
import togglePanel from './modules/togglePanel';
import swiperGallery from './modules/swiperGallery';
import viewportChange from './modules/viewportChange';
import viewMore from './modules/viewMore';
import tabpanel from './modules/tabpanel';
import autoHeight from './modules/autoHeight';
import currentClass from "./modules/currentClass";
import { throttle } from 'throttle-debounce';
import MicroModal from "micromodal";
import hNavScroll from "./modules/hNavScroll";

// function checkBreakPoint() {
//   if (window.matchMedia('(max-width: 767px)').matches) {
//     viewMore();
//   } else {
//     viewMore();
//   }
// }

window.addEventListener('resize', throttle(200, function () {
  // checkBreakPoint();
  autoHeight();
  viewMore();
}), false);

viewportChange();
hamburgerMenu();
smoothScroll();
togglePanel();
swiperGallery();
viewMore();
tabpanel();
autoHeight();
currentClass();
MicroModal.init();
hNavScroll();

// document.addEventListener('DOMContentLoaded', () => {

// });
