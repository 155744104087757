export default function hNavScroll() {
  const elements = document.querySelectorAll(`[aria-current="page"]`);

  elements.forEach(element => {
    const container = element.closest('.js-h-nav');
    if (! container) {
      return;
    }
    const targetPosition = element.offsetLeft - container.clientWidth + element.clientWidth;
    container.scrollTo({
      left: targetPosition,
      behavior: 'smooth'
    });
  });
}
