export default function viewMore() {
  const moreBtn = document.querySelectorAll('.js-more');
  moreBtn.forEach(function (button) {
    const moreContent = button.parentNode.previousElementSibling;
    if (window.matchMedia('(max-width: 767px)').matches) {
      if ( moreContent.scrollHeight < 400 ) {
        moreContent.classList.add('is-open');
        button.parentNode.classList.add('is-open');
      }
    } else if ( moreContent.scrollHeight < 200 ) {
      moreContent.classList.add('is-open');
      button.parentNode.classList.add('is-open');
    }
    button.addEventListener('click', () => {
      moreContent.style.maxHeight = moreContent.scrollHeight + 'px';
      moreContent.classList.add('is-open');
      button.parentNode.classList.add('is-open');
    });
  });
}
