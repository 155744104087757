export default function tabpanel() {
  const tabs = document.querySelectorAll('.js-tab');
  for (let i = 0; i < tabs.length; i++) {
    tabs[ i ].addEventListener('click', tabSwitch, false);
  }
  function tabSwitch() {
    const tabActive = document.querySelectorAll('[aria-selected="true"]')[ 0 ];
    tabActive.setAttribute('aria-selected', 'false');
    tabActive.setAttribute('aria-expanded', 'false');
    tabActive.setAttribute('tabindex', '-1');
    this.setAttribute('aria-selected', 'true');
    this.setAttribute('aria-expanded', 'true');
    this.setAttribute('tabindex', '0');

    const panelShow = document.querySelectorAll('[aria-hidden="false"]')[ 0 ];
    panelShow.setAttribute('aria-hidden', 'true');

    const arrayTabs = Array.prototype.slice.call(tabs);
    const index = arrayTabs.indexOf(this);
    const panel = document.querySelectorAll('.js-panel');
    panel[ index ].setAttribute('aria-hidden', 'false');
  }
  // //adjusting panel height
  // var maxHeight = 0;
  // const lists = document.querySelectorAll('.js-panel');
  // for (var i = 0; i < lists.length; i++) {
  //   if (lists[ i ].clientHeight > maxHeight) {
  //     maxHeight = lists[ i ].clientHeight;
  //   }
  // }
  // for (var j = 0; j < lists.length; j++) {
  //   lists[ j ].style.height = maxHeight + 'px';
  // }
}
