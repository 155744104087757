import Swiper from "swiper/bundle";

export default function swiperGallery() {
  const initSwiper = () => {
    const swiper = new Swiper(".js-swiper-thumb", {
      spaceBetween: 15,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          spaceBetween: 20,
          slidesPerView: 6,
        }
      }
    });
    const swiper2 = new Swiper(".js-swiper-body", { // eslint-disable-line no-unused-vars
      loop: true,
      thumbs: {
        swiper: swiper,
      },
    });
  };

  const initSwiperFig = () => {
    const swiper = new Swiper(".js-swiper-fig", { // eslint-disable-line no-unused-vars
      spaceBetween: 15,
      slidesPerView: 3,
      breakpoints: {
        768: {
          spaceBetween: 20,
          slidesPerView: 6,
        }
      }
    });
  };

  const initSwiperBnr = () => {
    const swiper = new Swiper(".js-swiper-bnr", { // eslint-disable-line no-unused-vars
      spaceBetween: 17,
      slidesPerView: 1,
      loop: true,
      breakpoints: {
        768: {
          spaceBetween: 21,
          slidesPerView: 3,
          loop: false,
        }
      }
    });
  };

  initSwiper();
  initSwiperFig();
  initSwiperBnr();
}
