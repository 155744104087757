import { fadeIn, fadeOut } from './fade';

export default function hamburgerMenu() {
  const hamburgerMenuButtons = document.querySelectorAll(
    '[data-trigger="hamburger"]'
  );
  const menuPanel = document.querySelector('#spMenu');

  hamburgerMenuButtons.forEach(function (hamburgerMenuButton) {
    hamburgerMenuButton.addEventListener(
      'click',
      function () {
        const html = document.querySelector('html');
        // const body = document.body; // ボタンの親要素
        if (html.dataset.showMenu === 'true') {
          html.dataset.showMenu = 'false';
          fadeOut(menuPanel);
        } else {
          html.dataset.showMenu = 'true';
          fadeIn(menuPanel);
        }
      },
      false
    );
  });
}
