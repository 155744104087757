import { slideUp, slideDown } from 'simple-slide-toggle';

export default function togglePanel() {
  const toggleBtn = document.querySelectorAll('.js-toggle');
  toggleBtn.forEach(function (button) {
    const toggleP = button.nextElementSibling;
    button.addEventListener('click', () => {
      if ('true' === button.getAttribute('aria-expanded')) {
        button.setAttribute('aria-expanded', 'false');
        button.setAttribute('aria-label', 'パネルを開く');
        toggleP.setAttribute('aria-hidden', 'true');
        slideUp(toggleP, 500);
      } else {
        button.setAttribute('aria-expanded', 'true');
        button.setAttribute('aria-label', 'パネルを閉じる');
        toggleP.setAttribute('aria-hidden', 'false');
        slideDown(toggleP, 500);
      }
    });
  });
}
